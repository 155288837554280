<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>회사관리 상세</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2 >회사정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>회사코드</label>
            <ul>
              <li>{{cmpyInfo.cmpyCd}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>회사구분</label>
            <ul>
              <li>{{cmpyInfo.cmpyGbnNm}}</li>
            </ul>
          </dd>
          <dd><label>사업자등록번호</label>
            <ul>
              <li>{{cmpyInfo.corporateRegNo}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>회사명</label>
            <ul>
              <li>{{cmpyInfo.cmpyNm}}</li>
            </ul>
          </dd>
          <dd><label>대표자명</label>
            <ul>
              <li>{{cmpyInfo.ceoNm}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>사업자등록증</label>
            <ul class="file_list">
              <li>
                <div v-if='!!cmpyInfo.corporateFileId' class="btn_file">
                  <a @click="EtnersEfsUtil.fileDownload(ApiConfig.efsDomain + '/efs/file/download/' + cmpyInfo.corporateFileId + '/1')">
                    <span class="k-icon k-i-download"></span>{{corporateImg}}
                  </a>
                </div>
              </li>
            </ul>
          </dd>
          <dd><label>대표이메일</label>
            <ul>
              <li>{{cmpyInfo.cmpyMainMail }}</li>
            </ul>
          </dd>
        </li>
       
        <li>
          <dd><label>주소</label>
            <ul>
              <li>{{cmpyInfo.addr}}</li>
            </ul>
          </dd>
          <dd><label>상세주소</label>
            <ul>
              <li> {{ cmpyInfo.addrDtl}}</li>
            </ul>
          </dd>
          
        </li>
        <li>
          <dd><label>식별 문자</label>
            <ul>
              <li>
                {{ cmpyInfo.cmpyUnqText }}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>등록</label>
            <ul>
              <li>{{cmpyInfo.frstRgstDt}} &nbsp | &nbsp {{cmpyInfo.frstRgstNm }}</li>
            </ul>
          </dd>
          <dd><label>최종수정</label>
            <ul>
              <li>{{cmpyInfo.lastMdfyDt}} &nbsp | &nbsp {{cmpyInfo.lastMdfyNm }}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>추가 접속 가능 관리자</h2>
      </div>
      <kendo-grid
          ref="companyMngGridRef"
          style="cursor: pointer; max-height: 360px"
          :class="'mgt20'"
          :sortable="false"
          :scrollable="true"
          :data-source="companyMngList"
          :columns="companyMngGridColumns"
          :selectable="'row'"
          @change="onChangeGrid"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      >
      </kendo-grid>
    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.push({ name: 'CompanyManageList' })">목록</button>
        </li>
        <li class="w30">
          <button class="large_btn dgray"
                  @click="onClickDeleteCompany">삭제</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="$router.push({name: 'CompanyManageEdit' })">수정</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersEfsUtil from '@/common/etners.efs.util.js'

export default {
  name   : 'CompanyManageDetail',
  created () {
    const vm = this
    vm.getCompanyManageDetail()
  },
  computed : {
    corporateImg : function() {
      if(this.cmpyInfo?.corporateFile?.length > 0){
        return this.cmpyInfo?.corporateFile[0]?.orgFileNm
      }else return null

    }
  },
  methods : {
    onChangeGrid (ev) {
      let cellIdx = window.event.target.cellIndex
      let boolForg = window.event.target.className === 'forg' // 키워드 강조 span태그 클릭시에도 deatil로 이동
      if (!isNaN(cellIdx) || boolForg) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)
        this.$router.push(
            { name: 'UserManageDetail',
              params: { unqUserId : rowData.unqUserId }
            })
      }
    },
    getCompanyManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/company/${vm.$route.params.cmpyCd}`)
      .then(function (response) {
          if (response.data.resultStatus.messageCode === '2000') {
            vm.cmpyInfo = response.data.resultData
            if(response.data.resultData.companyMngResponseList?.length > 0){
              vm.companyMngList = EtnersCommonUtil.setRowNumber(response.data.resultData.companyMngResponseList)
            }
          } else if(response.data.resultStatus.messageCode === '4431') {
            // 회사 상세 정보 조회권한이 없어 조회하실 수 없습니다. (접속가능 관리자 목록에 포함되어야만 조회가능합니다.)
            kendo.alert(response.data.resultStatus.messageText).bind('close',function (){
              vm.$router.push({ name: 'CompanyManageList'})
            })
          }else {
            kendo.alert(response.data.resultStatus.messageText)
          }
        },
      )
    },
    onClickDeleteCompany : function() {
      const vm = this
      kendo.confirm('회사를 삭제하시겠습니까?').done(function (){
        vm.deleteCompany()
      })
    },
    deleteCompany : function (){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/manage/company/${this.$route.params.cmpyCd}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              kendo.alert('삭제되었습니다.').bind('close', function() {
                vm.$router.push({ name: 'CompanyManageList' })
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    }
  },
    data   : function () {
      const vm = this
      const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
      const centerAttributes = { style: 'text-align: center;' }
      return {
        ApiConfig,
        EtnersEfsUtil,
        cmpyInfo  : {},
        companyMngList : [],
        companyMngGridColumns : [
          {
            field: 'rowNumber',
            title: 'No',
            width: '3%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'cmpyNm',
            title: '회사명',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'userNm',
            title: '성명',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'userId',
            title: 'ID',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
          {
            field: 'spTelNo',
            title: '휴대전화번호',
            width: '10%',
            headerAttributes: headerAttributes,
            attributes: centerAttributes
          },
        ],
      }
    },
  }
</script>
